<template>
  <div class="hello">
    <h1>WeatherDrobe</h1>
    <h2>What shall I wear in <input type="text" v-model="location" style="
    font-size: 80%;
"/>?</h2>
    <br><br>
    <img :src="'./svg/' + clothes1 + '.svg'" v-bind:alt="clothes1" width="50%" style="max-height:50vh" id="clothes1">
    <!--    <img :src="'./svg/' + clothes2 + '.svg'" v-bind:alt="clothes2" width="10%" id="clothes2">-->


    <br> <br>
    <h2>{{ kelvinToCelsius(weather.mainFeelsLike) }} Celsius</h2><br>
    <h2>{{ weather.weatherDescription }}</h2><br>
    <h2>{{ weather.area }}, {{ weather.sysCountry }}</h2>


  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'HelloWorld',
  mounted: function () {
    window.setInterval(() => {
          this.method1();
  }, 3000)
    this.$apollo.queries.weather.refetch()
    //method1 will execute at pageload
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        setTimeout(() => {
          this.method1();
        }, 30);


      }
    }

  },

  methods: {
    kelvinToCelsius(kelvin) {
      // console.log("WE GOT THE DATA");
      // this.method1()
      // console.log(kelvin)
      return Math.round(kelvin - 273.15);

    },
    method1() {
      var feelslikecelius = 0
      feelslikecelius = this.kelvinToCelsius(this.weather.mainFeelsLike)
      var rand = Math.random() * (8 - 0) + 0;
      var power = Math.pow(10, 2);
      this.colour = Math.floor(rand * power) / power;
      console.log(this.colour)
      document.getElementById("clothes1").style.filter = "hue-rotate(" + this.colour + "rad)";
      var body = document.body;
      body.className = "";
      var offset = Math.abs(this.weather.timezone) / 3600;
      var today = new Date();
      var h = today.getUTCHours();
      console.log(this.weather.timezone)
      console.log("offset")
      console.log(offset)

      h =h + Math.abs(offset)
      console.log(h + "before changing")
      if (h > 24) {
        h = h - 24
      }
      if (offset < 0){
        h = 24 - h
      }
      h = Math.abs(h)
      console.log("hour " + h)
      body.classList.add("sky-gradient-" + h);


      if (feelslikecelius >= 25) {
        this.clothes1 = this.hot[Math.floor(Math.random() * this.hot.length)];
        this.clothes2 = this.hot[Math.floor(Math.random() * this.freezing.length)];


      } else if (feelslikecelius <= 24 && feelslikecelius >= 15) {
        this.clothes1 = this.warm[Math.floor(Math.random() * this.warm.length)];
        this.clothes2 = this.warm[Math.floor(Math.random() * this.freezing.length)];


      } else if (feelslikecelius <= 14 && feelslikecelius >= 6) {
        this.clothes1 = this.cold[Math.floor(Math.random() * this.cold.length)];
        this.clothes2 = this.cold[Math.floor(Math.random() * this.freezing.length)];


      } else if (feelslikecelius <= 5) {
        this.clothes1 = this.freezing[Math.floor(Math.random() * this.freezing.length)];
        this.clothes2 = this.freezing[Math.floor(Math.random() * this.freezing.length)];


      }
    }
  },
  props: {
    clothes1: {
      default: "winter-coat",
      type: String
    },
    clothes2: {
      default: "fleece",
      type: String
    },
    location: {
      default: "London",
      type: String
    }
    ,
    colour: {
      default: "1",
      type: String
    }
    ,
    warm: {
      default: function () {
        return ["summer_hat", "summer_shirt", "mens_boxers", "mens_shorts", "jeans", "shorts", "summer_shoes",
          "t-shirt", "womens-glasses", "summer-dress", "sleeve-less-womens", "summer-jeans", "summer-skirt",
          "dress-shoe", "light-dress", "warm-skirt", "warm-dress", "girls-tshirt", "bikini-set", "summer-polo", "stripe-top",
          "w-top", "warm-skirt-2", "warmer-dress"]
      },
      type: Object
    },
    hot: {
      default: function () {
        return ["summer_hat", "summer_shirt", "vest", "mens_briefs", "mens_shorts",
          "shorts", "summer_shoes", "t-shirt", "womens-glasses", "summer-dress", "sleeve-less-womens", "swim-wear",
          "bikini-top", "bikini-bottom", "summer-jeans", "summer-shorts", "summer-skirt", "dress-shoe", "light-dress",
          "warm-skirt", "warm-dress", "girls-tshirt", "summer-polo", "stripe-top", "warm-skirt-2"]
      },
      type: Object
    },
    cold: {
      default: function () {
        return ["summer_hat", "light_jacket", "cold_trousers", "cold_shirt",
          "jeans", "thick_hoodie", "winter_shoes", "no-sleeve-jacket", "fleece", "long-sleeve", "long-sleeve2",
          "womens-winter-shoe", "dress-shoe", "women-blazer", "padded-jacket", "thin-jumper", "autumn-jacket",
          "winter-jacket", "cold-jumper", "cool-coat", "spring-jacket", "coldish-trop", "long-sleeve-cold",
          "cosy-hoody"]
      },
      type: Object
    }
    ,
    freezing: {
      default: function () {
        return ["cold_trousers", "jeans", "thick_hoodie", "winter_shoes", "winter-coat", "womens-winter-shoe",
          "padded-jacket", "autumn-jacket", "winter-jacket", "cold-jumper", "freezing-hat", "freezing-jumper",
          "ice-bally", "wool-hat"]
      },
      type: Object
    }

  }
  , watch: {
    weather: function r() {
      this.method1()
    }
  },

  apollo: {
    weather: {
      query: gql`query MyQuery($location_q: String!) {
  weather(location: $location_q) {
    mainFeelsLike
    weatherIcon
    weatherMain
    weatherDescription
    area
    sysCountry
    timezone
  }
}
`,
      variables() {
        // Use vue reactive properties here
        return {
          location_q: this.location,
        }
      }
    }
  }
}
</script>

<style>

h1 {
  mix-blend-mode: difference;
  background-color: #fff;


}



h2 {
  background-color: #fff;

  mix-blend-mode: difference;
  margin: 0 0 0 0;
}

.sky-gradient-00, .sky-gradient-24 {
  height: 100%;


  background: #00000c;
}

.sky-gradient-1 {
  height: 100%;

  background: linear-gradient(to bottom, #020111 85%, #191621 100%) fixed;
}

.sky-gradient-2 {
  height: 100%;


  background: linear-gradient(to bottom, #020111 60%, #20202c 100%) fixed;
}

.sky-gradient-3 {
  height: 100%;


  background: linear-gradient(to bottom, #020111 10%, #3a3a52 100%) fixed;
}

.sky-gradient-4 {
  height: 100%;


  background: linear-gradient(to bottom, #20202c 0%, #515175 100%) fixed;
}

.sky-gradient-5 {
  height: 100%;


  background: linear-gradient(to bottom, #40405c 0%, #6f71aa 80%, #8a76ab 100%) fixed;
}

.sky-gradient-6 {
  height: 100%;


  background: linear-gradient(to bottom, #4a4969 0%, #7072ab 50%, #cd82a0 100%) fixed;
}

.sky-gradient-7 {
  height: 100%;


  background: linear-gradient(to bottom, #757abf 0%, #8583be 60%, #eab0d1 100%) fixed;
}

.sky-gradient-8 {
  height: 100%;


  background: linear-gradient(to bottom, #82addb 0%, #ebb2b1 100%) fixed;
}

.sky-gradient-9 {
  height: 100%;


  background: linear-gradient(to bottom, #94c5f8 1%, #a6e6ff 70%, #b1b5ea 100%) fixed;
}

.sky-gradient-10 {
  height: 100%;


  background: linear-gradient(to bottom, #b7eaff 0%, #94dfff 100%) fixed;
}

.sky-gradient-11 {
  height: 100%;


  background: linear-gradient(to bottom, #9be2fe 0%, #67d1fb 100%) fixed;
}

.sky-gradient-12 {


  background: linear-gradient(to bottom, #90dffe 0%, #38a3d1 100%) fixed;
}

.sky-gradient-13 {
  height: 100%;


  background: linear-gradient(to bottom, #57c1eb 0%, #246fa8 100%) fixed;
}

.sky-gradient-14 {
  height: 100%;


  background: linear-gradient(to bottom, #2d91c2 0%, #1e528e 100%) fixed;
}

.sky-gradient-15 {
  height: 100%;


  background: linear-gradient(to bottom, #2473ab 0%, #1e528e 70%, #5b7983 100%) fixed;
}

.sky-gradient-16 {
  height: 100%;


  background: linear-gradient(to bottom, #1e528e 0%, #265889 50%, #9da671 100%) fixed;
}

.sky-gradient-17 {
  height: 100%;


  background: linear-gradient(to bottom, #1e528e 0%, #728a7c 50%, #e9ce5d 100%) fixed;
}

.sky-gradient-18 {
  height: 100%;


  background: linear-gradient(to bottom, #154277 0%, #576e71 30%, #e1c45e 70%, #b26339 100%) fixed;
}

.sky-gradient-19 {
  height: 100%;


  background: linear-gradient(to bottom, #163C52 0%, #4F4F47 30%, #C5752D 60%, #B7490F 80%, #2F1107 100%) fixed;
}

.sky-gradient-20 {
  height: 100%;


  background: linear-gradient(to bottom, #071B26 0%, #071B26 30%, #8A3B12 80%, #240E03 100%) fixed;
}

.sky-gradient-21 {
  height: 100%;


  background: linear-gradient(to bottom, #010A10 30%, #11294b 80%, #2F1107 100%) fixed;
}

.sky-gradient-22 {
  height: 100%;


  background: linear-gradient(to bottom, #090401 50%, #03123b 100%) fixed;
}

.sky-gradient-23 {
  height: 100%;


  background: linear-gradient(to bottom, #00000c 80%, #150800 100%) fixed;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
